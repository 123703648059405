import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DisabledPageLayout from "../pages/components/common/DisabledPageLayout";
import { selectIsInEditMode } from "../store/workbookSlice";
import { PageNames } from "../types";
import { isAuthTokenExist, isRefreshTokenExist } from "./auth";

export function AppContainer() {
  const isInCellEditMode = useSelector(selectIsInEditMode);
  const isAuthExist = isAuthTokenExist();
  const isRefreshToken = isRefreshTokenExist();

  if (!isAuthExist && !isRefreshToken) return <Navigate to={PageNames.SignIn} />;
  if (!isAuthExist && isRefreshToken) return <Navigate to={PageNames.DoSignIn} />;

  return (
    <DisabledPageLayout isDisabled={isInCellEditMode}>
      <Outlet />
    </DisabledPageLayout>
  );
}
