import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import CellEditModeAlert from "./CellEditModeAlert";

interface Props {
  isDisabled: boolean;
}

const DisabledPageLayout = ({ isDisabled, children }: PropsWithChildren<Props>) => {
  const layoutDisplayStyle = isDisabled ? "block" : "none";

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      {isDisabled && <CellEditModeAlert />}
      {children}
      {
        <Box
          sx={{
            display: layoutDisplayStyle,
            position: "fixed",
            inset: 0,
            backgroundColor: "rgba(241, 241, 241, 0.35)",
            zIndex: 9999,
            pointerEvents: "none",
          }}
        />
      }
      {
        <Box
          sx={{
            display: layoutDisplayStyle,
            position: "fixed",
            inset: 0,
            zIndex: 9998,
            pointerEvents: "all",
          }}
        />
      }
    </Box>
  );
};

export default DisabledPageLayout;
